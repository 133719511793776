import React from 'react'

export const PortfolioSimulator = () => {
  return (
    <main className="flex min-h-screen flex-1 items-center justify-center overflow-auto bg-gray-100">
      {/*<div className="m-8 justify-center border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">*/}
      {/*  <h3 className="mb-4 text-lg font-medium leading-6 text-gray-900 sm:mb-0">*/}
      {/*    Value finder*/}
      {/*  </h3>*/}
      {/*</div>*/}

      <div className={'rounded-xl bg-white px-44 py-24 text-lg shadow'}>
        Coming soon
      </div>
    </main>
  )
}
