import React, { FC } from 'react'

const StrategyScreen: FC = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384">
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384">
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384">
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse">
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/*<span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">*/}
            {/*  Strategy guide*/}
            {/*</span>*/}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              My strategy
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            As the name suggests, my strategy aims to produce a consistent
            return that can be withdrawn monthly as a supplementary or primary
            source of income. Personally, I am withdrawing $800 per month from
            my $100,000 account (10% annualized).
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            The basis of my strategy involves selling premium in the form of
            covered calls, and cash secured puts (often called the “wheel”
            strategy) on undervalued stocks with strong fundamentals.
          </p>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            This strategy can be simplified into just two components: buying the
            right companies, and selling the right contracts.
          </p>
        </div>
        <div className="mt-6 prose prose-teal prose-lg text-gray-500 mx-auto">
          <h2>
            <span className="block text-base text-center text-gray-400 font-semibold tracking-wide uppercase">
              Stock selection
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pick the right{' '}
              <span className={'text-primary-600'}>companies</span>
            </span>
          </h2>
          <p>
            The most important part of this strategy is that we pick the right
            companies to invest in. Both buy writes and cash secured puts are
            trades that cap our maximum return, while allowing a possible 100%
            loss of principal. If a stock goes against us, we either take the
            loss or wait and hope the price increases so we can sell premium
            again. Therefore, we must focus most of our energy on finding stocks
            that are undervalued, have strong fundamentals, and have a history
            of consistent growth. These are companies that theoretically should
            not have much further to drop in value. At the same time, these
            typically are not high growth stocks. This is also ideal for us, as
            any sharp increase in price would mean we would be assigned on our
            calls making a small, one-time profit, and then must decide between
            buying in at the higher valuation or finding other alternatives.
            Therefore, we typically pick stocks with relatively low, but
            consistent growth.
          </p>
          <p>
            The other main factor in stock selection is dividend yield. Since
            this is an income strategy that involves holding stock for long
            periods, a relatively high dividend yield is generally desirable,
            but not required. As previously stated, the worst thing that can
            happen is a drop in stock price, which will prevent us from selling
            premium until the price increases again. In this situation, if the
            stock already pays a high dividend, we don’t need to worry as much
            about the lack of premium selling as we will continue to receive the
            dividend. That being said, high dividend stocks tend to be riskier,
            which is why this comes secondary to selecting safe, fundamentally
            secure companies. We generally consider dividends to be a bonus on
            top of the premium which is our main source of income.
          </p>

          <h2>
            <span className="block text-base text-center text-gray-400 font-semibold tracking-wide uppercase">
              Option contract selection
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pick the right{' '}
              <span className={'text-primary-600'}>contracts</span>
            </span>
          </h2>
          <p>
            Now that we have selected companies, we must decide which type of
            contract to sell, and at what strike and expiration to sell it at.
            Generally, trading a buy write or a cash secured put should achieve
            a similar outcome. Often though, calls may pay more premium than
            puts, or vice-versa. This alone may not be enough to pick the better
            trade, as the other factor in the call vs put decision is the
            dividend. If the ex-dividend date falls between now and expiration,
            you will receive the dividend with a buy write but not with a put.
          </p>
          <p>
            The next factor in contract selection is the strike price. At the
            money strikes pay the highest premium but minimize your maximum
            possible gain, while out of the money strikes will increase your
            maximum possible gain but offer lest premium.
          </p>
          <p>
            The final factor is expiration date. Generally, the longer out a
            contract is, the more premium it will pay; however, this follows the
            law of diminishing returns, as the shorter contracts tend to have
            higher annualized returns. The weekly contracts always look the best
            in terms of annualized returns, yet these result in making
            significantly more transactions, and returns can be much less
            reliable if the stock were to turn against you after only selling a
            few calls. Long term contracts on the other hand provide more lower,
            but more predicable returns, and require fewer transactions. My
            general strategy here is to attempt to sell further out options when
            implied volatility is high to “lock in” the high rates for a long
            period, and to sell shorter duration contracts when implied
            volatility is low as we wait for a volatility spike. I generally
            default to one or two-month contracts, but will occasionally trade
            weeklies, or go out 3+ months. I also generally avoid opening new
            positions right before an earnings release, as this significantly
            increases the risk of the position quickly going against me even if
            it means higher potential returns.
          </p>
          <p>
            The “Option Chains” tool is designed to simplify this process by
            taking into account all of these factors and calculating an
            “expected return” for each contract. To learn about how those
            numbers are calculated, visit the{' '}
            <a href="/quickstart">“Getting Started”</a> tab.
          </p>
        </div>
      </div>
    </div>
  )
}

export default StrategyScreen
