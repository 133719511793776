import { create } from 'zustand'
import { OptionSettings, SimulationSettings } from './useSettingsStore'
import { StockSetting } from './useStockSettings'

export type Simulation = {
  id: string
  simulationSettings: SimulationSettings
  optionSettings: OptionSettings
  stockSettings: StockSetting[]
  status: 'Running' | 'Completed' | 'Error'
  timestamp: Date
  data?: any
}

type SimulationsStore = {
  simulations: Simulation[]
}

type SimulationsStoreActions = {
  reset: () => void
  addSimulation: (simulation: Simulation) => void
  updateSimulation: (simulation: Simulation) => void
  updateSimulationStatus: (id: string, status: Simulation['status']) => void
  updateSimulationData: (id: string, data: any) => void
}

const initialState: SimulationsStore = {
  simulations: [
    // {
    //   id: '1',
    //   simulationSettings: {
    //     name: 'New simulation',
    //     strategies: ['Long stock'],
    //     outputs: 'Combined',
    //     trials: 100000,
    //     currentPortfolio: false,
    //     correlateStocks: false,
    //   },
    //   optionSettings: {
    //     strikeCount: 20,
    //     expirationMonth: 'ALL',
    //     moneyness: 'ALL',
    //     minimumDays: 0,
    //     maximumDays: 504,
    //   },
    //   stockSettings: [
    //     {
    //       id: '1',
    //       symbol: 'SPY',
    //       growth_rate: 0,
    //       dividend_value_reduction: false,
    //       volatility_adjustment: 0,
    //     },
    //   ],
    //   status: 'Completed',
    //   timestamp: new Date(),
    // },
  ],
}

export const useSimulations = create<
  SimulationsStore & SimulationsStoreActions
>((set, get) => ({
  ...initialState,
  reset: () => set(initialState),
  addSimulation: (simulation: Simulation) =>
    set(state => ({ simulations: [...state.simulations, simulation] })),
  updateSimulation: (simulation: Simulation) =>
    set(state => ({
      simulations: state.simulations.map(s =>
        s.id === simulation.id ? simulation : s,
      ),
    })),
  updateSimulationStatus: (id: string, status: Simulation['status']) =>
    set(state => ({
      simulations: state.simulations.map(s =>
        s.id === id ? { ...s, status } : s,
      ),
    })),
  updateSimulationData: (id: string, data: any) =>
    set(state => ({
      simulations: state.simulations.map(s =>
        s.id === id ? { ...s, data } : s,
      ),
    })),
}))
