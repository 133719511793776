import React, { FC, Fragment, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'

import {
  BookOpenIcon,
  DocumentTextIcon,
  HomeIcon,
  XMarkIcon,
  Bars3CenterLeftIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
  WalletIcon,
} from '@heroicons/react/24/outline'

import { Images } from 'styles'
import { UserInfo } from '../index'

const primaryNavigation = [
  {
    name: 'Home',
    link: 'https://consistentincomeinvestor.com/',
    icon: HomeIcon,
    current: false,
  },
  {
    name: 'Getting started',
    path: '/quickstart',
    icon: DocumentTextIcon,
    current: true,
  },
  {
    name: 'My strategy',
    path: '/strategy',
    icon: BookOpenIcon,
    current: true,
  },
]

const navigation = [
  // {
  //   name: 'Option Chains',
  //   path: '/optionchains',
  //   icon: LinkIcon,
  //   current: false,
  // },
  // {
  //   name: 'Buy Write',
  //   path: '/buywrite',
  //   icon: LinkIcon,
  //   current: false,
  // },
  {
    name: 'Trade finder',
    path: '/tradefinder',
    icon: ArrowsUpDownIcon,
    current: false,
  },
  {
    name: 'Value finder',
    path: '/valuefinder',
    icon: MagnifyingGlassIcon,
    current: false,
  },
  {
    name: 'Portfolio simulator',
    path: '/portfoliosimulator',
    icon: WalletIcon,
    current: false,
  },
]

const footerNavigation = {
  main: [
    { name: 'Home', href: 'https://consistentincomeinvestor.com' },
    {
      name: 'Quickstart',
      href: 'https://app.consistentincomeinvestor.com/quickstart',
    },
    {
      name: 'My Strategy',
      href: 'https://app.consistentincomeinvestor.com/strategy',
    },
  ],
  social: [
    {
      name: 'Seeking Alpha',
      href: 'https://seekingalpha.com/author/consistent-income-investor',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            className="cls-1"
            d="M2.13,6.12c0,2.8,1.41,5.1,3.27,5.05,2.61-.09,3.37-2.27,3.37-5.08S7.26,1,5.4,1.02c-2.3,0-3.27,2.3-3.27,5.1M.13,6.12C.44,.14,5.13,0,5.13,0c3.41,0,4.37,3.65,4.37,3.65,.73-1.09,1.4-2.22,2-3.39h1.11c-2.27,5-2.18,7-2.18,7-.19,2.65,1.31,3.57,1.31,3.57,1.4,.89,1.48-1.71,1.48-1.71,.17-.06,.34-.14,.5-.22,.74,3.62-1.48,3.37-1.48,3.37-1.74,.18-2.48-2.64-2.48-2.64-2.46,3.24-5.29,2.54-5.29,2.54C-.38,11.55,0,6.17,0,6.17"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/ConsistIncome',
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Navigation: FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()

  return (
    <div className="min-h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-primary-700 pt-5">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-10 w-auto" src={Images.LOGO} alt="Workflow" />
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="mt-6 px-2">
                  <div className="mt-4 space-y-1">
                    {primaryNavigation.map(item =>
                      item.path ? (
                        <Link
                          key={item.name}
                          to={item.path}
                          className={classNames(
                            location.pathname === item.path
                              ? 'bg-primary-800 text-white'
                              : 'text-primary-100 hover:bg-primary-600',
                            'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                          )}
                          aria-current={
                            location.pathname === item.path ? 'page' : undefined
                          }>
                          <item.icon
                            className={
                              'mr-3 h-6 w-6 flex-shrink-0 text-primary-300'
                            }
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ) : (
                        <a
                          key={item.name}
                          href={item.link}
                          className={classNames(
                            location.pathname === item.path
                              ? 'bg-primary-800 text-white'
                              : 'text-primary-100 hover:bg-primary-600',
                            'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                          )}
                          aria-current={
                            location.pathname === item.path ? 'page' : undefined
                          }>
                          <item.icon
                            className={
                              'mr-3 h-6 w-6 flex-shrink-0 text-primary-300'
                            }
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ),
                    )}
                  </div>
                </nav>
                <nav className="mt-6 px-2">
                  <h3
                    className="px-3 text-xs font-semibold uppercase tracking-wider text-primary-50"
                    id="desktop-teams-headline">
                    Tools
                  </h3>

                  <div className="mt-4 space-y-1">
                    {navigation.map(item => (
                      <Link
                        key={item.name}
                        to={item.path}
                        className={classNames(
                          location.pathname === item.path
                            ? 'bg-primary-800 text-white'
                            : 'text-primary-100 hover:bg-primary-600',
                          'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5',
                        )}
                        aria-current={
                          location.pathname === item.path ? 'page' : undefined
                        }>
                        <item.icon
                          className={
                            'mr-3 h-6 w-6 flex-shrink-0 text-primary-300'
                          }
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
              <div className={'flex-1'}></div>
              <UserInfo />
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:bg-primary-700 lg:pt-5">
        <div className="flex flex-shrink-0 items-center px-6">
          <img
            className="h-10 w-auto"
            src={Images.LOGO}
            alt="Consistent Income Investor"
          />
        </div>
        <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
          <nav className="mt-6 px-3">
            <div className="mt-4 space-y-1">
              {primaryNavigation.map(item =>
                item.path ? (
                  <Link
                    key={item.name}
                    to={item.path}
                    className={classNames(
                      location.pathname === item.path
                        ? 'bg-primary-800 text-white'
                        : 'text-primary-100 hover:bg-primary-600',
                      'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                    )}
                    aria-current={
                      location.pathname === item.path ? 'page' : undefined
                    }>
                    <item.icon
                      className={'mr-3 h-6 w-6 flex-shrink-0 text-primary-300'}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ) : (
                  <a
                    key={item.name}
                    href={item.link}
                    className={classNames(
                      location.pathname === item.path
                        ? 'bg-primary-800 text-white'
                        : 'text-primary-100 hover:bg-primary-600',
                      'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                    )}
                    aria-current={
                      location.pathname === item.path ? 'page' : undefined
                    }>
                    <item.icon
                      className={'mr-3 h-6 w-6 flex-shrink-0 text-primary-300'}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ),
              )}
            </div>
          </nav>
          <nav className="mt-6 px-3">
            <h3
              className="px-3 text-xs font-semibold uppercase tracking-wider text-primary-50"
              id="desktop-teams-headline">
              Tools
            </h3>
            <div className="mt-4 space-y-1">
              {navigation.map(item => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={classNames(
                    location.pathname === item.path
                      ? 'bg-primary-800 text-white'
                      : 'text-primary-100 hover:bg-primary-600',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                  )}
                  aria-current={
                    location.pathname === item.path ? 'page' : undefined
                  }>
                  <item.icon
                    className={'mr-3 h-6 w-6 flex-shrink-0 text-primary-300'}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>

          <div className={'flex-1'}></div>
          <UserInfo />
        </div>
      </div>
      {/* Main column */}
      <div className="flex flex-col lg:pl-64">
        {/* Search header */}
        <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Outlet />
        <footer className="bg-gray-900">
          <div className="mx-auto max-w-md overflow-hidden py-12 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <nav
              className="-mx-5 -my-2 flex flex-wrap justify-center"
              aria-label="Footer">
              {footerNavigation.main.map((item: any) => (
                <div key={item.name} className="px-5 py-2">
                  <a
                    href={item.href}
                    className="text-base text-gray-400 hover:text-gray-300">
                    {item.name}
                  </a>
                </div>
              ))}
            </nav>
            <div className="mt-8 flex justify-center space-x-6">
              {footerNavigation.social.map((item: any) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex text-gray-400 hover:text-gray-300"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="mr-2">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-center text-base text-gray-400">
              Any of the trades or strategies discussed on this website or on
              other platforms should not be considered financial advice. The
              prices found on the “Option Chains” tool are not intended to be
              accurate stock market quotes. Data may be delayed or inaccurate.
              The returns calculated may contain errors and should not be used
              as an indication to buy or sell any securities. You invest at your
              own risk.
            </p>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Navigation
