import React from 'react'
import './App.css'
import RootNavigator from './navigations/RootNavigator'
import { analytics } from './config/fbConfig'
import { AuthContextProvider } from './services/authService'
import Feedback from './components/molecules/Feedback'

console.log(analytics.app.name) // required to setup and run FB config

function App() {
  return (
    <AuthContextProvider>
      <Feedback />
      <RootNavigator />
    </AuthContextProvider>
  )
}

export default App
