import {
  ExclamationCircleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/solid'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

type iFormInput = {
  symbol: string
  growth_rate: string
  risk_free_rate: string
  volatility: string
  volatility_years: string
  trials: string
}

export default function Form({
  onSubmit,
  disabled,
}: {
  onSubmit: (data: any) => void
  disabled: boolean
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<iFormInput>()

  const submit: SubmitHandler<iFormInput> = data => {
    console.log(data)

    const symbol = data.symbol !== '' ? data.symbol.toUpperCase() : 'AAPL'
    const growth_rate =
      data.growth_rate !== '' ? parseFloat(data.growth_rate) : 0.0
    const risk_free_rate =
      data.risk_free_rate !== '' ? parseFloat(data.risk_free_rate) : 4.0
    const volatility =
      data.volatility !== '' ? parseFloat(data.volatility) : 100.0
    const volatility_years =
      data.volatility_years !== '' ? parseFloat(data.volatility_years) : 1.0
    const trials = data.trials !== '' ? parseInt(data.trials) : 10000

    console.log({
      symbol,
      growth_rate,
      risk_free_rate,
      volatility,
      volatility_years,
      trials,
    })

    onSubmit({
      symbol,
      growth_rate: growth_rate / 100,
      risk_free_rate: risk_free_rate / 100,
      volatility: volatility / 100,
      volatility_years,
      trials,
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Headline
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Description of tool.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label
                htmlFor="symbol"
                className="block text-sm font-medium leading-6 text-gray-900">
                Symbol
              </label>
              <div className="relative mt-2">
                <input
                  {...register('symbol', { pattern: /^[A-Za-z]+$/i })}
                  type="text"
                  name="symbol"
                  id="symbol"
                  placeholder={'AAPL'}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                    errors?.symbol &&
                    'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                  }`}
                />
                {errors?.symbol && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 select-none text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors?.symbol && (
                <p className="mt-2 text-sm text-red-600" id="symbol-error">
                  Must be a valid stock symbol.
                </p>
              )}
            </div>

            <div className="sm:col-span-3 sm:col-start-1">
              <label
                htmlFor="growth_rate"
                className="block text-sm font-medium leading-6 text-gray-900">
                Yearly Growth Rate
              </label>
              <div className="relative mt-2">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">%</span>
                </div>
                <input
                  {...register('growth_rate')}
                  type="number"
                  name="growth_rate"
                  id="growth_rate"
                  placeholder={'0'}
                  step={'any'}
                  className={`block w-full rounded-md border-0 px-7 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                    errors?.growth_rate &&
                    'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                  }`}
                />
                {errors?.growth_rate && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 select-none text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors?.growth_rate && (
                <p className="mt-2 text-sm text-red-600" id="growth_rate-error">
                  Must be a valid number.
                </p>
              )}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="volatility"
                className="block text-sm font-medium leading-6 text-gray-900">
                Volatility Adjustment
              </label>
              <div className="relative mt-2">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">%</span>
                </div>
                <input
                  {...register('volatility', {
                    pattern: /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/,
                  })}
                  type="number"
                  name="volatility"
                  id="volatility"
                  placeholder={'100'}
                  step={'any'}
                  className={`block w-full rounded-md border-0 px-7 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                    errors?.volatility &&
                    'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                  }`}
                />
                {errors?.volatility && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 select-none text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors?.volatility?.type === 'pattern' && (
                <p className="mt-2 text-sm text-red-600" id="volatility-error">
                  Must be a positive non-zero number.
                </p>
              )}
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="trials"
                className="block text-sm font-medium leading-6 text-gray-900">
                Trials
              </label>
              <div className="relative mt-2">
                <input
                  {...register('trials', { min: 1, pattern: /^[1-9]\d*$/ })}
                  type="number"
                  name="trials"
                  id="trials"
                  placeholder={'10000'}
                  step={'1'}
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                    errors?.trials &&
                    'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                  }`}
                />
                {errors?.trials && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      className="h-5 w-5 select-none text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              {errors?.trials?.type === 'pattern' && (
                <p className="mt-2 text-sm text-red-600" id="trials-error">
                  Must be a positive integer.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
          Submit
        </button>
      </div>
    </form>
  )
}
