const DEV = false
const API_DEV = false

export const config = {
  redirectURI: DEV
    ? 'https://localhost:3000/welcome'
    : 'https://app.consistentincomeinvestor.com/welcome',
  clientID: DEV
    ? 'BYJMPOTYW18HTAT4HWQGSKHCLEBZXIFL'
    : 'WIEVXFCS1MAYRE7JDG5PHF0JB5GPYT8Y',
  apiURL: API_DEV
    ? 'http://127.0.0.1:5000'
    : 'https://backend-hz4gvpfhoa-ue.a.run.app',
}
