import React, { FC, useEffect, useState } from 'react'
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { useQuery } from '../../utils/useQuery'

const WelcomeScreen: FC = () => {
  return (
    <div>
      <div className="relative overflow-hidden bg-white py-16 ">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
          <div
            className="relative mx-auto h-full max-w-prose text-lg"
            aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384">
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384">
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="block text-center text-base font-semibold uppercase tracking-wide text-primary-600">
                Welcome
              </span>
              <span className="mt-2 block text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Let's get started
              </span>
            </h1>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              You are now signed in with your TD Ameritrade account. This gives
              you access to use our suite of data gathering tools.
            </p>

            <a
              className={'my-6 flex cursor-pointer items-center'}
              href={'/quickstart'}>
              <ArrowRightCircleIcon
                className="h-10 w-10 text-primary-500"
                aria-hidden="true"
              />
              <p className={'ml-4 text-xl font-bold'}>
                View our quick start guide
              </p>
            </a>

            <a
              className={'my-6 flex cursor-pointer items-center'}
              href={'/strategy'}>
              <ArrowRightCircleIcon
                className="h-10 w-10 text-primary-500"
                aria-hidden="true"
              />
              <p className={'ml-4 text-xl font-bold'}>
                Learn about our strategy
              </p>
            </a>
          </div>
          <div className="prose prose-lg prose-teal mx-auto mt-6 text-gray-500">
            <p>
              To get started right away you can start using the Option Chains
              tool. You can find all our tools listed on the side bar.
            </p>

            <a href="/tradefinder" className={'no-underline'}>
              <button className="block w-full rounded-md border border-transparent bg-primary-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10 ">
                Check out the tools
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className={'h-[400px]'}></div>
    </div>
  )
}

export default WelcomeScreen

const Callback = () => {
  const query = useQuery()
  const code = query.get('code')

  const [token, setToken] = useState(null)

  useEffect(() => {
    if (code) {
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

      const urlencoded = new URLSearchParams()
      urlencoded.append('grant_type', 'authorization_code')
      urlencoded.append('refresh_token', '')
      urlencoded.append('access_type', 'offline')
      urlencoded.append('code', code)
      urlencoded.append('client_id', 'BYJMPOTYW18HTAT4HWQGSKHCLEBZXIFL')
      urlencoded.append('redirect_uri', 'https://localhost:3000/callback')

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
      }

      // @ts-ignore
      fetch('https://api.tdameritrade.com/v1/oauth2/token', requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setToken(result)
        })
        .catch(error => console.log('error', error))
    }
  }, [code])

  return (
    <div>
      <p>Response code</p>
      <p>{code}</p>
      <p>Token</p>
      <p>{JSON.stringify(token)}</p>
    </div>
  )
}
