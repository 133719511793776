import { create } from 'zustand'

export type SimulationSettings = {
  name: string
  strategies: Array<'Long stock' | 'Short stock' | 'Buy write'>
  outputs: 'Combined'
  trials: number
  currentPortfolio: boolean
  correlateStocks: boolean
}

export type OptionSettings = {
  strikeCount: number
  expirationMonth:
    | 'ALL'
    | 'JAN'
    | 'FEB'
    | 'MAR'
    | 'APR'
    | 'MAY'
    | 'JUN'
    | 'JUL'
    | 'AUG'
    | 'SEP'
    | 'OCT'
    | 'NOV'
    | 'DEC'
  moneyness: 'ALL' | 'ITM' | 'NTM' | 'OTM' | 'SAK' | 'SBK' | 'SNK'
  minimumDays: number
  maximumDays: number
}

type SettingsStore = {
  simulationSettings: SimulationSettings
  optionSettings: OptionSettings
}

type SettingsStoreActions = {
  reset: () => void
  updateSimulationName: (name: string) => void
  updateSimulationSettings: (settings: SimulationSettings) => void
  updateOptionSettings: (settings: OptionSettings) => void
}

const initialState: SettingsStore = {
  simulationSettings: {
    name: 'New simulation',
    strategies: ['Long stock', 'Buy write'],
    outputs: 'Combined',
    trials: 100000,
    currentPortfolio: false,
    correlateStocks: false,
  },
  optionSettings: {
    strikeCount: 20,
    expirationMonth: 'ALL',
    moneyness: 'ALL',
    minimumDays: 0,
    maximumDays: 504,
  },
}

export const useSettingsStore = create<SettingsStore & SettingsStoreActions>(
  (set, get) => ({
    ...initialState,
    reset: () => set(initialState),
    updateSimulationName: (name: string) =>
      set({ simulationSettings: { ...get().simulationSettings, name } }),
    updateSimulationSettings: (settings: SimulationSettings) =>
      set({ simulationSettings: settings }),
    updateOptionSettings: (settings: OptionSettings) =>
      set({ optionSettings: settings }),
  }),
)
