import { RssIcon } from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'
import { Popover, Transition, Listbox } from '@headlessui/react'
import { ChevronDownIcon, PaperClipIcon } from '@heroicons/react/24/solid'
import { Octokit } from '@octokit/core'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Feedback = () => {
  const [feedback, setFeedback] = React.useState('')
  const handleSubmit = async (e: any, close: any) => {
    e.preventDefault()
    close()
    console.log('submit', feedback)
    // Octokit.js
    // https://github.com/octokit/core.js#readme
    const octokit = new Octokit({
      auth: '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',
    })

    await octokit.request('POST /repos/gstravous/consistentincome-r/issues', {
      owner: 'gstravous',
      repo: 'consistentincome-r',
      title: 'Website Feedback',
      body: '## Feedback\n\n' + feedback,
      assignees: ['gstravous'],
      labels: ['feedback'],
    })
  }

  return (
    <div className={'fixed bottom-0 right-0 z-10 m-8'}>
      <Popover className="">
        {({ open, close }) => (
          <>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="fixed bottom-0 mb-24  w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <div className="flex items-start space-x-4">
                      <div className="min-w-0 flex-1">
                        <form
                          action="#"
                          className="relative"
                          onSubmit={e => handleSubmit(e, close)}>
                          <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
                            <label htmlFor="comment" className="sr-only">
                              Add your comment
                            </label>
                            <textarea
                              rows={3}
                              name="comment"
                              id="comment"
                              className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
                              placeholder="Add your comment..."
                              defaultValue={''}
                              onChange={e => setFeedback(e.target.value)}
                            />

                            {/* Spacer element to match the height of the toolbar */}
                            <div className="py-2" aria-hidden="true">
                              {/* Matches height of button in toolbar (1px border + 36px content height) */}
                              <div className="py-px">
                                <div className="h-9" />
                              </div>
                            </div>
                          </div>

                          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                            <div className="flex items-center space-x-5"></div>
                            <div className="flex-shrink-0">
                              <button
                                type="submit"
                                className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                Post
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>

            <Popover.Button className="flex flex-1 justify-center rounded-md border border-transparent bg-primary-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
              <button type="button" className={'flex'}>
                <RssIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Provide feedback
              </button>
            </Popover.Button>
          </>
        )}
      </Popover>
    </div>
  )
}

export default Feedback
