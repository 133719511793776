import React from 'react'
import { Simulations } from './components/simulations'
import { Settings } from './components/settings'
import { StockSettings } from './components/stockSettings'
import { NameInput } from './components/nameInput'
import { useTradeFinder } from './hooks/useTradeFinder'
import { useAuthState } from '../../../services/authService'
import { NoAuth } from './components/noAuth'
import { useSettingsStore } from './hooks/useSettingsStore'
import { useStockSettingsStore } from './hooks/useStockSettings'

export const TradeFinderScreen = () => {
  const { runSimulation, saveSimulation, reset } = useTradeFinder()
  const { auth, refresh, isAuthenticated, logout } = useAuthState()
  const { optionSettings, simulationSettings } = useSettingsStore()
  const { settings: stockSettings } = useStockSettingsStore()

  const handleClear = () => {
    reset()
  }

  const handleRunSimulation = async () => {
    const sim = await saveSimulation({
      optionSettings,
      simulationSettings,
      stockSettings,
    })

    await runSimulation(sim)
  }

  if (!isAuthenticated) return <NoAuth />

  return (
    <main className="min-h-screen flex-1 overflow-auto bg-gray-100">
      <div className="m-8 justify-center border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 className="mb-4 text-lg font-medium leading-6 text-gray-900 sm:mb-0">
          Trade finder
        </h3>

        <div className={'flex space-x-2'}>
          <button
            type="button"
            className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            onClick={handleClear}>
            Clear
          </button>
          <button
            type="button"
            className="relative inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            onClick={handleRunSimulation}>
            Run simulation
          </button>
        </div>
      </div>

      <div className={'mx-8'}>
        <NameInput />
      </div>

      <div className="mx-8 flex flex-col lg:flex-row">
        <div className="order-1 flex-grow py-2 lg:order-2 lg:pl-2">
          <StockSettings />
        </div>
        <div className="order-2 flex-none lg:order-1 lg:flex lg:flex-col">
          <div className="py-2 lg:pr-2">
            <Settings />
          </div>
          <div className="py-2 lg:pr-2">
            <Simulations />
          </div>
        </div>
      </div>
    </main>
  )
}
