import React, { useState } from 'react'
import { useSettingsStore } from '../hooks/useSettingsStore'
import Overlay from './overlay'
import { SimulationSettingForm } from './modals/simulationSettingsForm'
import { OptionSettingForm } from './modals/optionSettingsForm'

const simulationSettingsKeys = [
  {
    name: 'Strategies',
    key: 'strategies',
  },
  {
    name: 'Outputs',
    key: 'outputs',
  },
  {
    name: 'Trials',
    key: 'trials',
  },
  {
    name: 'Current Portfolio',
    key: 'currentPortfolio',
  },
  {
    name: 'Correlate Stocks',
    key: 'correlateStocks',
  },
]

const optionSettingsKeys = [
  {
    name: 'Strike Count',
    key: 'strikeCount',
  },
  {
    name: 'Expiration Month',
    key: 'expirationMonth',
  },
  {
    name: 'Moneyness',
    key: 'moneyness',
  },
  {
    name: 'Minimum Days',
    key: 'minimumDays',
  },
  {
    name: 'Maximum Days',
    key: 'maximumDays',
  },
]

export const Settings = () => {
  const {
    optionSettings,
    simulationSettings,
    updateSimulationSettings,
    updateOptionSettings,
  } = useSettingsStore()
  const [open, setOpen] = useState(false)
  const [settingsType, setSettingsType] = useState<'simulation' | 'option'>(
    'simulation',
  )

  const handleEditSimulationSettings = () => {
    setSettingsType('simulation')
    setOpen(true)
  }

  const handleEditOptionSettings = () => {
    setSettingsType('option')
    setOpen(true)
  }

  const handleSubmitSimulation = (values: any) => {
    console.log('Updated simulation settings', values)
    updateSimulationSettings(values)
    setOpen(false)
  }

  const handleSubmitOptions = (values: any) => {
    console.log('Updated option settings', values)
    updateOptionSettings(values)
    setOpen(false)
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Simulation Settings
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={handleEditSimulationSettings}>
                Edit
              </button>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className={'grid grid-cols-3 gap-4 text-sm'}>
            {simulationSettingsKeys.map(item => (
              <div key={item.key}>
                <div className={'text-gray-400'}>{item.name}</div>
                {item.key !== 'strategies' ? (
                  <div>
                    {/* @ts-ignore */}
                    {simulationSettings[item.key].toString()}
                  </div>
                ) : (
                  <div>
                    {simulationSettings[item.key].map(value => (
                      <div key={value}>{value}</div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Option Settings
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={handleEditOptionSettings}>
                Edit
              </button>
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className={'grid grid-cols-3 gap-4 text-sm'}>
            {optionSettingsKeys.map(item => (
              <div key={item.key}>
                <div className={'text-gray-400'}>{item.name}</div>
                {/* @ts-ignore */}
                <div>{optionSettings[item.key]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Overlay
        open={open}
        setOpen={setOpen}
        title={
          settingsType === 'simulation'
            ? 'Simulation Settings'
            : 'Option Settings'
        }>
        {settingsType === 'simulation' ? (
          <SimulationSettingForm
            initial={simulationSettings}
            onCancel={() => setOpen(false)}
            onSubmit={handleSubmitSimulation}
          />
        ) : (
          <OptionSettingForm
            initial={optionSettings}
            onCancel={() => setOpen(false)}
            onSubmit={handleSubmitOptions}
          />
        )}
      </Overlay>
    </>
  )
}
