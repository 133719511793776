import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useForm } from 'react-hook-form'
import { OptionSettings } from '../../hooks/useSettingsStore'

type iFormInput = OptionSettings

export const OptionSettingForm = ({
  initial,
  onSubmit: onSubmitProp = () => {},
  onCancel = () => {},
}: {
  initial?: OptionSettings
  onSubmit?: (data: OptionSettings) => void
  onCancel?: () => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<iFormInput>({
    defaultValues: initial,
  })

  const onSubmit = handleSubmit(data => {
    let output = data

    output.strikeCount = Number(output.strikeCount)
    output.minimumDays = Number(output.minimumDays)
    output.maximumDays = Number(output.maximumDays)

    onSubmitProp(output)
  })

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200"
      onSubmit={onSubmit}>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="strikeCount"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Strike Count
                </label>
                <div className="relative mt-2">
                  <input
                    {...register('strikeCount', {
                      pattern: /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/,
                    })}
                    type="number"
                    name="strikeCount"
                    id="strikeCount"
                    placeholder={'20'}
                    step={1}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.strikeCount &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.strikeCount && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.strikeCount?.type === 'pattern' && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="strikeCount-error">
                    Must be a positive non-zero number.
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="expirationMonth"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Expiration Month
                </label>
                <select
                  {...register('expirationMonth')}
                  id="expirationMonth"
                  name="expirationMonth"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  defaultValue="ALL">
                  <option>ALL</option>
                  <option>JAN</option>
                  <option>FEB</option>
                  <option>MAR</option>
                  <option>APR</option>
                  <option>MAY</option>
                  <option>JUN</option>
                  <option>JUL</option>
                  <option>AUG</option>
                  <option>SEP</option>
                  <option>OCT</option>
                  <option>NOV</option>
                  <option>DEC</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="moneyness"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Moneyness
                </label>
                <select
                  {...register('moneyness')}
                  id="moneyness"
                  name="moneyness"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  defaultValue="ALL">
                  <option>ALL</option>
                  <option>ITM</option>
                  <option>NTM</option>
                  <option>OTM</option>
                  <option>SAK</option>
                  <option>SBK</option>
                  <option>SNK</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="minimumDays"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Minimum Days
                </label>
                <div className="relative mt-2">
                  <input
                    {...register('minimumDays', { min: 0 })}
                    type="number"
                    name="minimumDays"
                    id="minimumDays"
                    placeholder={'0'}
                    step={1}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.minimumDays &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.minimumDays && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.minimumDays && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="minimumDays-error">
                    Must be a positive number.
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="maximumDays"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Maximum Days
                </label>
                <div className="relative mt-2">
                  <input
                    {...register('maximumDays', { min: 1 })}
                    type="number"
                    name="maximumDays"
                    id="maximumDays"
                    placeholder={'504'}
                    step={1}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.maximumDays &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.maximumDays && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.maximumDays && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="maximumDays-error">
                    Must be a positive number greater than 0.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onCancel}>
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
          Save
        </button>
      </div>
    </form>
  )
}
