import { useEffect, useState } from 'react'
import moment from 'moment'

const calculateSecondsSince = (timestamp: any) => {
  const duration = moment.duration(moment().diff(moment(timestamp)))
  return Math.floor(duration.asSeconds())
}

export const useTimeFromNow = (timestamp: any) => {
  const [timeFromNow, setTimeFromNow] = useState(moment(timestamp).fromNow())
  const [secondsFromNow, setSecondsFromNow] = useState(
    calculateSecondsSince(timestamp),
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeFromNow(moment(timestamp).fromNow())
      setSecondsFromNow(calculateSecondsSince(timestamp))
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timestamp])

  return { timeFromNow, secondsFromNow }
}
