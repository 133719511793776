'use client'

import React from 'react'
import {
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline'
import { Simulation, useSimulations } from '../hooks/useSimulations'
import moment from 'moment'
import { useTimeFromNow } from '../hooks/useTimeFromNow'
import { useTradeFinder } from '../hooks/useTradeFinder'
import { useSettingsStore } from '../hooks/useSettingsStore'
import { useStockSettingsStore } from '../hooks/useStockSettings'

const statuses = {
  Completed: 'text-green-700 bg-green-50 ring-green-600/10',
  Running: 'text-gray-600 bg-gray-50 ring-gray-500/20',
  Error: 'text-red-700 bg-red-50 ring-red-600/10',
}

export const Simulations = () => {
  const { simulations } = useSimulations()
  return (
    <div className="overflow-hidden">
      <div className="py-5">
        <h2 className="text-base font-semibold leading-7">Simulations</h2>
        <table className="w-full whitespace-nowrap text-left">
          <thead className="">
            <tr>
              <th scope="col" className="sr-only">
                Name
              </th>
              <th scope="col" className="sr-only">
                Status
              </th>
              <th scope="col" className="sr-only">
                Options
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-black/5">
            {[...simulations].reverse().map(simulation => (
              <SimulationRow simulation={simulation} key={simulation.id} />
            ))}
          </tbody>
        </table>
        {simulations.length === 0 && (
          <div className="mt-8 flex h-96 flex-col items-center">
            <p className="text-sm text-gray-400">
              No simulations have been run yet.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

const SimulationRow = ({ simulation }: { simulation: Simulation }) => {
  const { timeFromNow, secondsFromNow } = useTimeFromNow(simulation.timestamp)
  const { downloadSimulation, reRunSimulation } = useTradeFinder()
  const {
    updateOptionSettings,
    updateSimulationSettings,
    updateSimulationName,
  } = useSettingsStore()
  const { setSettings } = useStockSettingsStore()

  const handleSetSimulation = (simulation: Simulation) => {
    setSettings(simulation.stockSettings)
    updateOptionSettings(simulation.optionSettings)
    updateSimulationSettings(simulation.simulationSettings)
    updateSimulationName(simulation.simulationSettings.name)
  }

  return (
    <tr>
      <td className="py-4 pl-0 pr-6 sm:pl-6 lg:pl-6">
        <div className="max-w-[8rem] truncate text-sm font-medium leading-6 lg:max-w-[14rem]">
          {simulation.simulationSettings.name}
        </div>
        <div className="text-sm leading-6 text-gray-400">
          <time dateTime={moment(simulation.timestamp).toISOString()}>
            {timeFromNow}
          </time>
        </div>
      </td>
      <td className="py-4 pl-0 pr-4 sm:pr-8">
        <div className="flex gap-x-3">
          <span
            className={`${
              statuses[simulation.status]
            } inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset`}>
            {simulation.status}
          </span>
        </div>
      </td>
      <td className="py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:pr-6 lg:pr-8">
        {simulation.status === 'Running' && <div>{secondsFromNow} sec</div>}
        {simulation.status !== 'Running' && (
          <div className={'flex items-center justify-end space-x-2'}>
            <ArrowPathIcon
              className="h-5 w-5 cursor-pointer select-none text-primary-500  hover:animate-spin hover:text-gray-500"
              aria-hidden="true"
              onClick={() => reRunSimulation(simulation.id)}
            />
            <Square2StackIcon
              className="h-5 w-5 cursor-pointer select-none text-primary-500  hover:text-gray-500"
              aria-hidden="true"
              onClick={() => handleSetSimulation(simulation)}
            />
            {simulation.status === 'Completed' && (
              <ArrowDownOnSquareIcon
                className="h-5 w-5 cursor-pointer select-none text-primary-500  hover:text-gray-500"
                aria-hidden="true"
                onClick={() => downloadSimulation(simulation.id)}
              />
            )}
          </div>
        )}
      </td>
    </tr>
  )
}
