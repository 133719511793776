import { create } from 'zustand'

export type StockSetting = {
  id: string
  symbol: string
  growth_rate: number
  dividend_pay?: number
  dividend_value_reduction: boolean
  volatility?: number
  volatility_adjustment: number
}

type StockSettingsStore = {
  settings: StockSetting[]
}

type StockSettingsStoreActions = {
  reset: () => void
  addSetting: (setting: StockSetting) => void
  updateSetting: (setting: StockSetting) => void
  removeSetting: (id: string) => void
  setSettings: (settings: StockSetting[]) => void
}

const initialState: StockSettingsStore = {
  settings: [
    // {
    //   id: '1',
    //   symbol: 'SPY',
    //   growth_rate: 0,
    //   dividend_value_reduction: false,
    //   volatility_adjustment: 0,
    // },
  ],
}

export const useStockSettingsStore = create<
  StockSettingsStore & StockSettingsStoreActions
>((set, get) => ({
  ...initialState,
  reset: () => set(initialState),
  addSetting: (setting: StockSetting) =>
    set(state => ({ settings: [...state.settings, setting] })),
  updateSetting: (setting: StockSetting) =>
    set(state => ({
      settings: state.settings.map(s => (s.id === setting.id ? setting : s)),
    })),
  removeSetting: (id: string) =>
    set(state => ({ settings: state.settings.filter(s => s.id !== id) })),
  setSettings: (settings: StockSetting[]) => set({ settings }),
}))
