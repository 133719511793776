import React, { FC } from 'react'

const QuickstartScreen: FC = () => {
    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg
                        className="absolute top-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg
                        className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg
                        className="absolute bottom-12 left-full transform translate-x-32"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">
              Quickstart guide
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              How it Works
            </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        When trading the wheel strategy or selling covered calls and cash secured puts, picking the right contracts to sell can be confusing. How do we know what strike price and expiration date to choose? And how do we know if it is better to start with a buy write or a cash secured put? The “Option Chains” tool simplifies the process of contract selection down to a few seconds and allows you to select the contracts with the highest “expected return” given one or multiple symbols.
                    </p>
                </div>
                <div className="mt-6 prose prose-teal prose-lg text-gray-500 mx-auto">
                    <p>
                        Simply input one or more symbols, and a list of possible trades will be returned. In addition to the typical information found on an option chain, you will also see the derived columns of <strong>“Assumed Fill”</strong>, <strong>“Profit”</strong>, <strong>“Risk”</strong>, <strong>“ROR”</strong> (return on risk), and <strong>“IRR”</strong> (internal rate of return or annualized return). The column we are most concerned with is the IRR column, as the trades with the highest expected IRR are theoretically the most desirable. This as well as the other columns can be easily sorted highest to lowest by clicking on the column header. Above the table on the left you will see other filter options that allow you to eliminate certian stocks, strikes, expiration dates, or show only calls/puts. You will also see a few different checkbox options to the right of the symbol input box which affect how the returns are calculated. By default, these are all turned on, as taking into account all of these factors should paint the most realistic picture of expected returns. 
                    </p>

                    <h2>Different Methods of Calculating Returns</h2>
                    <p>
                        The first checkbox “Dividend” simply adds any dividends received between now and the expiration of the contract into the profit calculation. Be sure to turn this off if the stock you enter pays no dividend or else the tool will not work. 
                    </p>
                    <p>
                        The second checkbox “Dividend Drop” assumes the stock price will drop by the dividend amount after the dividend is received, and then slowly rise each as it approaches the next dividend date. This reflects the reality that stock prices historically tend to drop by the dividend amount the day after the ex-dividend date. This can only be turned on if the dividend is also turned on. It is reccomended to turn this on if you also have the dividend on because it will paint a more realistic picture of returns.
                    </p>
                    <p>
                        Finally, the “Use Historic Volatility” checkbox determines the price we assume the stock will be at expiration. This determines how the profit column is calculated. If left unchecked, we simply assume the stock price stays the same from now until expiration (or drops by the dividend if turned on). From this expiration price, we can easily calculate what our profit should be given the strike price and the premium recieved which for us is the "Assumed Fill". If we do check this box, we follow the same calculation, but instead of assuming the price at expiration is the same as it is today, we consider hundreds of possible expiration prices. These possible expiration prices are derived from the historical volatility of the underlying stock and form a normal distribution or a “bell curve”. In other words, we are looking at how the stock has moved in the past and are predicting how it is likely to move in the future. Now that we have hundreds of possible expiration prices, we can again calculate the return at each price. Finally, we multiply each return by the probability of achieving that return and adding them all together for one final “expected return”. Below are the specific formulas I use to calculate each return given the expiration price, premium, strike price, and expiration date.
                    </p>

                    <h2>Calls</h2>

                    <p>
                        All call option trades assume you are opening 1 new Buy Write position (buying 100 shares of underlying stock and selling 1 contract of the specified call). Therefore, the assumed risk is equal to the cost of underlying stock minus the premium received.
                    </p>

                    <blockquote>
                        <p>
                            Risk = (Purchase Price – Premium) x 100
                        </p>
                    </blockquote>

                    <p>
                        The profit calculation we use depends on whether the expiration price is in the money (ITM) or out of the money (OTM). We also assume that we hold all positions until expiration and let them expire or be assigned. Therefore, if we sell a call that ends up OTM, we simply receive the premium as our profit. If we sell an ITM call, we still receive the premium, but must subtract the difference between our purchase price, and our sold price (strike price), since we assume we will get assigned and sell at a lower price than we bought.
                    </p>

                    <blockquote>
                        <p>
                            for OTM Calls, Profit = (Expiration Price - Purchase Price + Premium) x 100
                        </p>
                    </blockquote>

                    <blockquote>
                        <p>
                            for ITM Calls, Profit = (Premium – Purchase Price + Strike) x 100
                        </p>
                    </blockquote>

                    <p>
                        Return on investment (ROI) and IRR are calculated as they traditionally would be. For ROI we divide our profit by our risk. For IRR we divide our ROI by the number of trading days until expiration, and multiply by 252 trading days per year.
                    </p>

                    <blockquote>
                        <p>
                            ROI = Profit / Risk
                        </p>
                    </blockquote>

                    <blockquote>
                        <p>
                            IRR = (ROI / market days to expiration) x 252
                        </p>
                    </blockquote>

                    <h2>Puts</h2>

                    <p>
                        All put option trades assume you are selling to open one cash secured put. Therefore, your risk is equal to the strike minus the premium received.
                    </p>

                    <blockquote>
                        <p>
                            Risk = (Strike – Premium) x 100
                        </p>
                    </blockquote>

                    <p>
                        Like with calls, we assume that we will allow our contracts to either be assigned or expire. Therefore, if we sell an OTM put option, we simply receive the premium as our profit, and that the contract expires worthless. If we sell an ITM put, we assume we get assigned and purchase the stock at the strike. We then take the difference between that and the expiration price, since we assume the stock price will remain the same, and finally add the premium received.
                    </p>

                    <blockquote>
                        <p>
                            For OTM Puts, Profit = Premium x 100
                        </p>
                    </blockquote>

                    <blockquote>
                        <p>
                            For ITM Puts, Profit = (Premium + Expiration Price – Strike) x 100
                        </p>
                    </blockquote>

                    <p>
                        The “ROI” and “IRR” calculations are the same as they are for calls.
                    </p>
                    
                    <p>
                        Note that for all of our calculations, the premium recieved is the "Assumed Fill" column. With tight bid-ask spreads, this should be closer to the "Mid", and with wide spreads this will be closer to the "Ask". And for all ITM contracts, it will be equal to the "Ask".
                    </p>
                    
                    {/*<figure>*/}
                    {/*    <img*/}
                    {/*        className="w-full rounded-lg"*/}
                    {/*        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"*/}
                    {/*        alt=""*/}
                    {/*        width={1310}*/}
                    {/*        height={873}*/}
                    {/*    />*/}
                    {/*    <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption>*/}
                    {/*</figure>*/}
                </div>
            </div>
        </div>
    )
}

export default QuickstartScreen
