import React, { useState } from 'react'
import { StockSetting, useStockSettingsStore } from '../hooks/useStockSettings'
import Overlay from './overlay'
import { StockSettingForm } from './modals/stockSettingForm'
import { TrashIcon } from '@heroicons/react/24/outline'

export const StockSettings = () => {
  const { settings, addSetting, updateSetting, removeSetting } =
    useStockSettingsStore()
  const [open, setOpen] = useState(false)
  const [selectedSetting, setSelectedSetting] = useState<StockSetting | null>(
    null,
  )

  const handleAdd = () => {
    setSelectedSetting(null)
    setOpen(true)
  }

  const handleEdit = (setting: StockSetting) => {
    setSelectedSetting(setting)
    setOpen(true)
  }

  const handleRemove = (setting: StockSetting) => {
    removeSetting(setting.id)
  }

  const handleSubmit = (values: StockSetting) => {
    console.log('Added setting', values)
    if (selectedSetting) {
      updateSetting(values)
    } else {
      addSetting(values)
    }
    setOpen(false)
  }

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="mt-5 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Stock Settings
              </h1>
              {/*<p className="mt-2 text-sm text-gray-700">*/}
              {/*  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas*/}
              {/*</p>*/}
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={handleAdd}>
                Add new
              </button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                        Symbol
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Yearly Growth Rate
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Dividend Pay
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Dividend Value Reduction
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Yearly Volatility
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Volatility Adjustment
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {settings.map(setting => (
                      <tr key={setting.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                          <span
                            className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium uppercase text-gray-600 ring-1 ring-inset ring-gray-500/20`}>
                            {setting.symbol}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          % {setting.growth_rate.toFixed(1)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {setting.dividend_pay !== undefined
                            ? `$ ${setting.dividend_pay.toFixed(2)}`
                            : 'Default'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span
                            className={`inline-flex items-center rounded-full  px-2 py-1 text-xs font-medium lowercase ring-1 ring-inset ${
                              setting.dividend_value_reduction
                                ? 'bg-green-50 text-green-700 ring-green-600/10'
                                : 'bg-red-50 text-red-700 ring-red-600/10'
                            }`}>
                            {setting.dividend_value_reduction.toString()}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {setting.volatility !== undefined
                            ? `% ${setting.volatility.toFixed(0)}`
                            : 'Default'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          % {setting.volatility_adjustment.toFixed(1)}
                        </td>
                        <td className="relative flex items-center justify-end space-x-4 whitespace-nowrap py-4 pl-3 pr-4 sm:pr-6 lg:pr-8">
                          <button onClick={() => handleRemove(setting)}>
                            <TrashIcon
                              className="h-5 w-5 select-none text-gray-300 hover:text-red-500"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            className="text-right text-sm font-medium text-primary-600 hover:text-primary-900"
                            onClick={() => handleEdit(setting)}>
                            Edit
                            <span className="sr-only">, {setting.symbol}</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {settings.length === 0 && (
                  <div className="flex h-32 flex-col items-center justify-center">
                    <div className="mb-4 text-xl text-gray-400">
                      No stock settings
                    </div>
                    <button
                      type="button"
                      className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                      onClick={handleAdd}>
                      Add new
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Overlay open={open} setOpen={setOpen} title={'Add setting'}>
        <StockSettingForm
          setting={selectedSetting}
          onCancel={() => setOpen(false)}
          onSubmit={handleSubmit}
        />
      </Overlay>
    </>
  )
}
