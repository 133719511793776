import React, { FC, useEffect, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
} from 'react-router-dom'

import { Navigation } from 'components'
import NoMatchScreen from 'scenes/nomatch'

import QuickstartScreen from 'scenes/quickstart'
import StrategyScreen from 'scenes/strategy'
import WelcomeScreen from '../scenes/welcome'
import BuyWriteExpectedReturnsScreen from '../scenes/tools/buyWriteExpectedReturns'
import { TradeFinderScreen } from '../scenes/tools/tradeFinder'
import { PortfolioSimulator } from '../scenes/tools/portfolioSimulator'
import { ValueFinder } from '../scenes/tools/valueFinder'

const RootNavigator: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<QuickstartScreen />} />
        <Route path="*" element={<NoMatchScreen />} />
        <Route path="quickstart" element={<QuickstartScreen />} />
        <Route path="strategy" element={<StrategyScreen />} />
        <Route path="buywrite" element={<BuyWriteExpectedReturnsScreen />} />
        <Route path="tradefinder" element={<TradeFinderScreen />} />
        <Route path="valuefinder" element={<ValueFinder />} />
        <Route path="portfoliosimulator" element={<PortfolioSimulator />} />
        <Route path="welcome" element={<WelcomeScreen />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default RootNavigator
