import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useForm } from 'react-hook-form'
import { StockSetting } from '../../hooks/useStockSettings'
import { v4 as uuidv4 } from 'uuid'

type iFormInput = {
  symbol: string
  growth_rate: number
  dividend_pay?: number
  dividend_value_reduction: boolean
  volatility?: number
  volatility_adjustment: number
}

const defaultValues: iFormInput = {
  symbol: 'SPY',
  growth_rate: 0,
  dividend_pay: undefined,
  dividend_value_reduction: false,
  volatility: undefined,
  volatility_adjustment: 0,
}

const replaceWithDefault = (data: Record<string, any>) => {
  const output: any = {}

  Object.keys(data).forEach(key => {
    if (data[key] === '') {
      output[key] = defaultValues[key as keyof iFormInput]
    } else {
      output[key] = data[key]
    }
  })

  return output
}

const toNumber = (value: string | number | undefined) =>
  value === undefined ? undefined : Number(value)

export const StockSettingForm = ({
  setting,
  onSubmit: onSubmitProp = () => {},
  onCancel = () => {},
}: {
  setting?: StockSetting | null
  onSubmit?: (data: StockSetting) => void
  onCancel?: () => void
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<iFormInput>({
    defaultValues: setting ?? undefined,
  })

  const onSubmit = handleSubmit(data => {
    let output = replaceWithDefault(data)

    output.symbol = output.symbol.toUpperCase()
    output.growth_rate = toNumber(output.growth_rate)
    output.dividend_pay = toNumber(output.dividend_pay)
    output.volatility = toNumber(output.volatility)
    output.volatility_adjustment = toNumber(output.volatility_adjustment)

    onSubmitProp({
      id: setting?.id ?? uuidv4(),
      ...output,
    })
  })

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200"
      onSubmit={onSubmit}>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="symbol"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Symbol
                </label>
                <div className="relative mt-2">
                  <input
                    {...register('symbol', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                    type="text"
                    name="symbol"
                    id="symbol"
                    placeholder={'SPY'}
                    className={`block w-full rounded-md border-0 py-1.5 uppercase text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.symbol &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.symbol && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.symbol && (
                  <p className="mt-2 text-sm text-red-600" id="symbol-error">
                    Must be a valid stock symbol.
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="growth_rate"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Yearly Growth Rate
                </label>
                <div className="relative mt-2">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">%</span>
                  </div>
                  <input
                    {...register('growth_rate')}
                    type="number"
                    name="growth_rate"
                    id="growth_rate"
                    placeholder={'0'}
                    step={'any'}
                    className={`block w-full rounded-md border-0 px-7 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.growth_rate &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.growth_rate && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.growth_rate && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="growth_rate-error">
                    Must be a valid number.
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="dividend_pay"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Dividend Pay
                </label>
                <div className="relative mt-2">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    {...register('dividend_pay')}
                    type="number"
                    name="dividend_pay"
                    id="dividend_pay"
                    placeholder={'Default'}
                    step={'any'}
                    className={`block w-full rounded-md border-0 px-7 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.dividend_pay &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.dividend_pay && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.dividend_pay && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="dividend_pay-error">
                    Must be a valid number.
                  </p>
                )}
              </div>

              <div className="relative ml-4 flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    {...register('dividend_value_reduction')}
                    id="dividend_value_reduction"
                    aria-describedby="dividend-value-reduction"
                    name="dividend_value_reduction"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="dividend_value_reduction"
                    className="font-medium text-gray-900">
                    Enable dividend value reduction
                  </label>
                  {/*<p id="dividend-value-reduction" className="text-gray-500">*/}
                  {/*  Dividend value reduction is a method of reducing the value*/}
                  {/*  of a dividend by a percentage.*/}
                  {/*</p>*/}
                </div>
              </div>

              <div>
                <label
                  htmlFor="volatility"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Yearly Volatility
                </label>
                <div className="relative mt-2">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">%</span>
                  </div>
                  <input
                    {...register('volatility', {
                      pattern: /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/,
                    })}
                    type="number"
                    name="volatility"
                    id="volatility"
                    placeholder={'Default'}
                    step={'any'}
                    className={`block w-full rounded-md border-0 px-7 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.volatility &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.volatility && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.volatility?.type === 'pattern' && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="volatility-error">
                    Must be a positive non-zero number.
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="volatility_adjustment"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Volatility Adjustment
                </label>
                <div className="relative mt-2">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">%</span>
                  </div>
                  <input
                    {...register('volatility_adjustment')}
                    type="number"
                    name="volatility_adjustment"
                    id="volatility_adjustment"
                    placeholder={'0'}
                    step={'any'}
                    className={`block w-full rounded-md border-0 px-7 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.volatility_adjustment &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.volatility_adjustment && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.volatility_adjustment && (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="volatility_adjustment-error">
                    Must be a valid number.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onCancel}>
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
          Save
        </button>
      </div>
    </form>
  )
}
