import {
  OptionSettings,
  SimulationSettings,
  useSettingsStore,
} from './useSettingsStore'
import { Simulation, useSimulations } from './useSimulations'
import { StockSetting, useStockSettingsStore } from './useStockSettings'
import { v4 as uuidv4 } from 'uuid'
import { config } from '../../../../config/config'
import { useAuthState } from '../../../../services/authService'
import JSZip from 'jszip'
import { Buffer } from 'buffer'

const strategiesMap = {
  'Long stock': 'long_stock',
  'Short stock': 'short_stock',
  'Buy write': 'buy_write',
}

export const useTradeFinder = () => {
  const {
    updateSimulationName,
    updateSimulationSettings,
    updateOptionSettings,
    reset: resetSettings,
  } = useSettingsStore()
  const {
    addSimulation,
    updateSimulationStatus,
    updateSimulationData,
    simulations,
  } = useSimulations()
  const {
    settings,
    addSetting,
    reset: resetStockSettings,
  } = useStockSettingsStore()
  const { auth, refresh, isAuthenticated, logout } = useAuthState()

  const reset = () => {
    updateSimulationName('')
    resetSettings()
    resetStockSettings()
  }

  const helper = async (
    data: {
      optionSettings: OptionSettings
      simulationSettings: SimulationSettings
      stockSettings: StockSetting[]
    },
    authParam?: {
      access_token: string
      expires_in: number
      refresh_token: string
      refresh_token_expires_in: number
      scope: string
      token_type: string
      userId: string
    },
  ) => {
    if (!authParam && !auth) {
      logout()
      return
    }

    const body = {
      optionSettings: data.optionSettings,
      simulationSettings: {
        ...data.simulationSettings,
        strategies: data.simulationSettings.strategies.map(
          strategy => strategiesMap[strategy],
        ),
        outputs: data.simulationSettings.outputs.toLowerCase(),
      },
      stockSettings: data.stockSettings.map(setting => {
        let newSetting = setting

        newSetting.symbol = setting.symbol.toUpperCase()

        return newSetting
      }),
    }

    try {
      const res = await fetch(`${config.apiURL}/trade-finder/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            authParam ? authParam.access_token : auth.access_token
          }`,
        },
        body: JSON.stringify(body),
        redirect: 'follow',
      })

      if (res.status === 400) {
        const data = await res.json()

        throw new Error(data.error)
      }

      return res.json()
    } catch (e) {
      console.log({ e })
    }
  }

  const saveSimulation = async (data: {
    optionSettings: OptionSettings
    simulationSettings: SimulationSettings
    stockSettings: StockSetting[]
  }) => {
    const sim: Simulation = {
      id: uuidv4(),
      optionSettings: data.optionSettings,
      simulationSettings: data.simulationSettings,
      stockSettings: data.stockSettings,
      status: 'Running',
      timestamp: new Date(),
    }

    addSimulation(sim)

    return sim
  }

  const runSimulation = async (sim: Simulation) => {
    const data = {
      optionSettings: sim.optionSettings,
      simulationSettings: sim.simulationSettings,
      stockSettings: sim.stockSettings,
    }

    try {
      const res = await helper(data)

      // todo handle errors

      if (!res) throw new Error('No response')

      console.log({ res })

      updateSimulationStatus(sim.id, 'Completed')
      updateSimulationData(sim.id, res.data)
    } catch (e) {
      console.error(e)
      try {
        const res = await helper(data, await refresh())

        // todo handle errors
        if (!res) throw new Error('No response')

        updateSimulationStatus(sim.id, 'Completed')
        updateSimulationData(sim.id, res.data)
      } catch (e) {
        console.error(e)
        updateSimulationStatus(sim.id, 'Error')
      }
    }
  }

  const downloadSimulation = async (id: string) => {
    const simulation = simulations.find(sim => sim.id === id)

    if (!simulation) return

    const data = simulation.data

    if (!data) return

    const zip = new JSZip()

    console.log(data)

    data.forEach((file: { content: string; filename: string }) => {
      let binary = Buffer.from(file.content, 'base64').toString('binary')
      let array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }

      zip.file(file.filename, new Uint8Array(array))
    })

    const content = await zip.generateAsync({ type: 'blob' })

    let blob = new Blob([content], { type: 'application/zip' })

    let link = document.createElement('a')

    link.download = `${simulation.simulationSettings.name}.zip`

    link.href = window.URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const reRunSimulation = async (id: string) => {
    const simulation = simulations.find(sim => sim.id === id)
    if (!simulation) return

    const sim = await saveSimulation({
      optionSettings: simulation.optionSettings,
      simulationSettings: simulation.simulationSettings,
      stockSettings: simulation.stockSettings,
    })
    await runSimulation(sim)
  }

  return {
    reset,
    runSimulation,
    reRunSimulation,
    saveSimulation,
    downloadSimulation,
  }
}
