import React, { createContext, useContext, useEffect, useState } from 'react'
import tdService from './tdService'
import { config } from '../config/config'

const storageKeys = {
  AUTH: 'AUTH',
}

export const AuthContext = createContext(null)

export const AuthContextProvider = (props: any) => {
  const [auth, setAuth] = useState<{
    access_token: string
    expires_in: number
    refresh_token: string
    refresh_token_expires_in: number
    scope: string
    token_type: string
    userId: string
  } | null>(null)

  useEffect(() => {
    console.log('Attempting to load from storage...')
    const dataString = sessionStorage.getItem(storageKeys.AUTH)
    if (dataString) {
      const data = JSON.parse(dataString)
      console.log('Loaded from storage')
      setAuth(data)
    } else {
      console.log('Data not found in storage.')
    }
  }, [])

  const login = () => {
    window.open(
      `https://auth.tdameritrade.com/auth?response_type=code&client_id=${
        config.clientID
      }%40AMER.OAUTHAP&scope=AccountAccess&redirect_uri=${encodeURIComponent(
        config.redirectURI,
      )}`,
    )
  }

  const authenticate = async (code: string, redirect: boolean = false) => {
    const result = await tdService.codeToAuthToken(code)
    console.log({ result })

    if (result.error) return

    const info = await tdService.getUserInfo(result.access_token)

    if (info.error) return

    const auth = {
      access_token: result.access_token,
      expires_in: result.expires_in,
      refresh_token: result.refresh_token,
      refresh_token_expires_in: result.refresh_token_expires_in,
      scope: result.scope,
      token_type: result.token_type,
      userId: info.userId,
    }

    setAuth(auth)

    sessionStorage.setItem(storageKeys.AUTH, JSON.stringify(auth))
    if (redirect) window.open(`/welcome`, '_self')
  }

  const logout = () => {
    setAuth(null)
    sessionStorage.clear()
  }

  const refresh = () =>
    new Promise(async (resolve, reject) => {
      console.log('Refreshing token...')
      if (auth?.refresh_token) {
        const result = await tdService.refreshToken(auth.refresh_token)

        if (result.error) return

        const updated = {
          ...auth,
          access_token: result.access_token,
          expires_in: result.expires_in,
          scope: result.scope,
          token_type: result.token_type,
        }

        setAuth(updated)

        sessionStorage.setItem(storageKeys.AUTH, JSON.stringify(updated))
        console.log('Token refreshed.')
        resolve(updated)
      } else {
        console.log('No refresh token found.')
        logout()
        reject()
      }
    })

  const isAuthenticated = auth?.access_token

  return (
    <AuthContext.Provider
      value={{ login, logout, authenticate, refresh, auth, isAuthenticated }}
      {...props}
    />
  )
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  // @ts-ignore
  return { ...auth }
}
