import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useForm } from 'react-hook-form'
import { SimulationSettings } from '../../hooks/useSettingsStore'

type iFormInput = SimulationSettings & {
  strategy_long_stock: boolean
  strategy_short_stock: boolean
  strategy_buy_write: boolean
}

export const SimulationSettingForm = ({
  initial,
  onSubmit: onSubmitProp = () => {},
  onCancel = () => {},
}: {
  initial?: SimulationSettings
  onSubmit?: (data: SimulationSettings) => void
  onCancel?: () => void
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<iFormInput>({
    defaultValues: {
      ...initial,
      strategy_long_stock: initial?.strategies.includes('Long stock'),
      strategy_short_stock: initial?.strategies.includes('Short stock'),
      strategy_buy_write: initial?.strategies.includes('Buy write'),
    },
  })

  const onSubmit = handleSubmit(data => {
    let output = data

    output.strategies = []
    if (data.strategy_long_stock) output.strategies.push('Long stock')
    if (data.strategy_short_stock) output.strategies.push('Short stock')
    if (data.strategy_buy_write) output.strategies.push('Buy write')

    output.trials = Number(output.trials)

    onSubmitProp(output)
  })

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200"
      onSubmit={onSubmit}>
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="strategies"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Strategies
                </label>
                <div className="relative ml-4 mt-2 flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      {...register('strategy_long_stock')}
                      id="strategy_long_stock"
                      aria-describedby="strategy_long_stock"
                      name="strategy_long_stock"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="strategy_long_stock"
                      className="font-medium text-gray-900">
                      Long stock
                    </label>
                  </div>
                </div>
                <div className="relative ml-4 mt-2 flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      {...register('strategy_short_stock')}
                      id="strategy_short_stock"
                      aria-describedby="strategy_short_stock"
                      name="strategy_short_stock"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="strategy_short_stock"
                      className="font-medium text-gray-900">
                      Short stock
                    </label>
                  </div>
                </div>
                <div className="relative ml-4 mt-2 flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      {...register('strategy_buy_write')}
                      id="strategy_buy_write"
                      aria-describedby="strategy_buy_write"
                      name="strategy_buy_write"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="strategy_buy_write"
                      className="font-medium text-gray-900">
                      Buy write
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="expirationMonth"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Outputs
                </label>
                <select
                  {...register('outputs')}
                  id="outputs"
                  name="outputs"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  defaultValue="Combined">
                  <option>Combined</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="trials"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Trials
                </label>
                <div className="relative mt-2">
                  <input
                    {...register('trials', { min: 10000, max: 100000 })}
                    type="number"
                    name="trials"
                    id="trials"
                    placeholder={'100000'}
                    step={1}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 ${
                      errors?.trials &&
                      'text-red-900 ring-2 ring-red-500 focus:ring-red-500'
                    }`}
                  />
                  {errors?.trials && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 select-none text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
                {errors?.trials && (
                  <p className="mt-2 text-sm text-red-600" id="trials-error">
                    Must be a number between 10,000 and 100,000.
                  </p>
                )}
              </div>

              <div className="relative ml-4 flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    {...register('currentPortfolio')}
                    id="currentPortfolio"
                    aria-describedby="currentPortfolio"
                    name="currentPortfolio"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="currentPortfolio"
                    className="font-medium text-gray-900">
                    Current Portfolio
                  </label>
                  {/*<p id="currentPortfolio" className="text-gray-500">*/}
                  {/*  Current portfolio is a method of simulating a portfolio.*/}
                  {/*</p>*/}
                </div>
              </div>

              <div className="relative ml-4 flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    {...register('correlateStocks')}
                    id="correlateStocks"
                    aria-describedby="correlateStocks"
                    name="correlateStocks"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="correlateStocks"
                    className="font-medium text-gray-900">
                    Correlate Stocks
                  </label>
                  {/*<p id="correlateStocks" className="text-gray-500">*/}
                  {/*  Correlate stocks is a method of simulating a portfolio.*/}
                  {/*</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onCancel}>
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">
          Save
        </button>
      </div>
    </form>
  )
}
