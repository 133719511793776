import React, { FC, useEffect, useState } from 'react'
import Form from './form'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import {
  DocumentArrowDownIcon,
  ArrowPathIcon,
  ExclamationCircleIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline'
import moment from 'moment/moment'
import { useAuthState } from '../../../services/authService'
import OauthPopup from 'react-oauth-popup'
import { config } from '../../../config/config'
import Papa from 'papaparse'

const headers = [
  'Symbol',
  'Growth Rate',
  // 'Risk Free Rate',
  'Volatility',
  // 'Volatility Years',
  'Trials',
  'Date',
  'Time',
]

const keys = [
  'symbol',
  'growth_rate',
  // 'risk_free_rate',
  'volatility',
  // 'volatility_years',
  'trials',
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const BuyWriteExpectedReturnsScreen: FC = () => {
  const { auth, refresh, isAuthenticated, logout } = useAuthState()

  const [items, setItems] = useState<
    {
      id: string
      symbol: string
      growth_rate: number
      risk_free_rate: number
      volatility: number
      volatility_years: number
      trials: number
      data: {
        data: any[]
        headers: string[]
        img_base64: string
      } | null
      created_at: string
      error?: any
    }[]
  >([
    // {
    //     id: '1',
    //     symbol: 'AAPL',
    //     growth_rate: 0.1,
    //     volatility: 0.2,
    //     volatility_years: 1,
    //     trials: 1000,
    //     data: null,
    //     created_at: new Date().toISOString(),
    //         error: 'anything'
    // }
  ])

  const [loading, setLoading] = useState(false)

  async function helper(
    data: {
      symbol: string
      growth_rate: number
      risk_free_rate: number
      volatility: number
      volatility_years: number
      trials: number
    },
    authParam?: {
      access_token: string
      expires_in: number
      refresh_token: string
      refresh_token_expires_in: number
      scope: string
      token_type: string
      userId: string
    },
  ) {
    const {
      symbol,
      growth_rate,
      risk_free_rate,
      volatility,
      volatility_years,
      trials,
    } = data

    if (!authParam && !auth) {
      logout()
      return
    }

    const id = Math.random().toString(36).substring(2) + Date.now().toString(36)

    const newItems = [
      ...items,
      {
        id,
        symbol,
        growth_rate,
        risk_free_rate,
        volatility,
        volatility_years,
        trials,
        data: null,
        created_at: new Date().toISOString(),
      },
    ]

    await setItems(newItems)

    try {
      const res = await fetch(
        `${config.apiURL}/buy-write/expected-returns/${symbol}`,
        {
          method: 'POST',
          headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${
              authParam ? authParam.access_token : auth.access_token
            }`,
          },
          body: JSON.stringify({
            growth_rate,
            volatility,
            volatility_years,
            trials,
          }),
        },
      )

      if (res.status === 400) {
        const data = await res.json()

        throw new Error(data.error)
      }

      const data = await res.json()

      await setItems(prevState =>
        prevState.map(item => {
          if (item.id === id) {
            return {
              ...item,
              data,
            }
          }
          return item
        }),
      )
    } catch (e) {
      console.error(e)

      setItems(prevState =>
        prevState.map(item => {
          if (item.id === id) {
            return {
              ...item,
              error: e,
            }
          }
          return item
        }),
      )
    }
  }

  async function onSubmit(data: {
    symbol: string
    growth_rate: number
    risk_free_rate: number
    volatility: number
    volatility_years: number
    trials: number
  }) {
    console.log('submitting...')

    setLoading(true)

    try {
      await helper(data)
    } catch (e) {
      console.error(e)
      try {
        const freshAuth = await refresh()
        await helper(data, freshAuth)
      } catch (e) {
        console.error(e)
      }
    }

    setLoading(false)
  }

  function handleDownloadImage(item: any) {
    const {
      id,
      symbol,
      growth_rate,
      risk_free_rate,
      volatility,
      volatility_years,
      trials,
      data: { img_base64 },
      created_at,
    } = item

    const url = `data:image/png;base64,${img_base64}`
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute(
      'download',
      `${symbol.toUpperCase()} [${growth_rate},${risk_free_rate},${volatility},${volatility_years},${trials}] ${moment(
        created_at,
      ).format('YYYY_MM_DD HH.mm.ss')}.png`,
    )
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  function handleDownloadCSV(item: any) {
    const {
      id,
      symbol,
      growth_rate,
      risk_free_rate,
      volatility,
      volatility_years,
      trials,
      data: { data, headers },
      created_at,
    } = item

    const csv = Papa.unparse({
      fields: headers,
      data: data.map((row: any) => {
        return headers.map((header: string) => row[header] || '')
      }),
    })

    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute(
      'download',
      `${symbol.toUpperCase()} [${growth_rate},${volatility},${trials}] ${moment(
        created_at,
      ).format('YYYY_MM_DD HH.mm.ss')}.csv`,
    )
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  if (!isAuthenticated) return <NoAuth />

  return (
    <main className="min-h-screen flex-1">
      {/* Page title & actions */}
      <div className="m-8 justify-center border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Buy Write Expected Returns
        </h3>
      </div>

      <div className={'m-8'}>
        <Form onSubmit={onSubmit} disabled={loading} />
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={header}
                        scope="col"
                        className="select-none px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                        <div className="flex">
                          <div className="">{header}</div>
                        </div>
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="select-none px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"></th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => {
                    return (
                      <tr
                        key={`${item}${index}`}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                        {keys.map((key, index) => (
                          <td
                            key={key}
                            className={classNames(
                              'whitespace-nowrap px-6 py-4 text-sm',
                              index === 0
                                ? 'font-medium text-gray-900'
                                : 'text-gray-500',
                            )}>
                            {(() => {
                              switch (key) {
                                case 'growth_rate':
                                case 'risk_free_rate':
                                case 'volatility':
                                case 'volatility_years':
                                  return item[key].toFixed(2)
                                default:
                                  // @ts-ignore
                                  return item[key]
                              }
                            })()}
                          </td>
                        ))}

                        <td
                          className={
                            'whitespace-nowrap px-6 py-4 text-sm text-gray-500'
                          }>
                          {moment(item.created_at).format('MM/DD/YYYY')}
                        </td>

                        <td
                          className={
                            'whitespace-nowrap px-6 py-4 text-sm text-gray-500'
                          }>
                          {moment(item.created_at).format('hh:mm A')}
                        </td>

                        {item.error && (
                          <td
                            className={
                              'whitespace-nowrap px-6 py-4 text-sm text-red-500'
                            }>
                            <ExclamationCircleIcon className="h-5 w-5" />
                          </td>
                        )}

                        {!item.data && !item.error && (
                          <td
                            className={
                              'whitespace-nowrap px-6 py-4 text-sm text-primary-500'
                            }>
                            <ArrowPathIcon className="h-5 w-5 animate-spin" />
                          </td>
                        )}

                        {item.data && !item.error && (
                          <td
                            className={
                              'flex flex-row space-x-2 whitespace-nowrap px-6 py-4 text-sm'
                            }>
                            {/*<span*/}
                            {/*  className={*/}
                            {/*    'cursor-pointer text-primary-500 hover:text-primary-400'*/}
                            {/*  }*/}
                            {/*  onClick={() => handleDownloadImage(item)}>*/}
                            {/*  <PhotoIcon className="h-5 w-5" />*/}
                            {/*</span>*/}
                            <span
                              className={
                                'cursor-pointer text-primary-500 hover:text-primary-400'
                              }
                              onClick={() => handleDownloadCSV(item)}>
                              <DocumentArrowDownIcon className="h-5 w-5" />
                            </span>
                          </td>
                        )}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default BuyWriteExpectedReturnsScreen

const NoAuth = () => {
  const { authenticate } = useAuthState()
  const onCode = (code: string, params: any) => {
    authenticate(code)
  }
  const onClose = () => console.log('Auth popup closed')

  return (
    <div className={'min-h-screen'}>
      <div className="relative overflow-hidden bg-white py-16 ">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
          <div
            className="relative mx-auto h-full max-w-prose text-lg"
            aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384">
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384">
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="block text-center text-base font-semibold uppercase tracking-wide text-primary-600">
                Buy Write Expected Returns
              </span>
              <span className="mt-2 block text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Let's get started
              </span>
            </h1>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              To start using the expected returns tool you will first need to
              sign in with your TD Ameritrade account. This gives you access to
              use our suite of data gathering tools.
            </p>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              If you don't have an account, you can sign up for free.
            </p>
          </div>
          <div className="prose prose-lg prose-teal mx-auto mt-6 text-gray-500">
            {/*@ts-ignore */}
            <OauthPopup
              url={`https://auth.tdameritrade.com/auth?response_type=code&client_id=${
                config.clientID
              }%40AMER.OAUTHAP&scope=AccountAccess&redirect_uri=${encodeURIComponent(
                config.redirectURI,
              )}`}
              onCode={onCode}
              onClose={onClose}
              title={'Authenticate'}>
              <button className="block w-full rounded-md border border-transparent bg-primary-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10 ">
                Login with TD Ameritrade
              </button>
            </OauthPopup>
          </div>
        </div>
      </div>
    </div>
  )
}
