import React from 'react'
import { useSettingsStore } from '../hooks/useSettingsStore'

export const NameInput = () => {
  const { updateSimulationName } = useSettingsStore()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSimulationName(e.target.value)
  }

  return (
    <div className={'mb-2.5'}>
      <div className="flex rounded-md shadow-sm">
        <div className="relative -mr-px inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300">
          Simulation name
        </div>
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            type="text"
            name="simulation-name"
            id="simulation-name"
            className="block w-full rounded-none rounded-r-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
            placeholder="New simulation"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  )
}
