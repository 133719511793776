// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD0sm3z4NnbMfySpX_TeMljsYMi4TdSHGg',
  authDomain: 'consistent-income.firebaseapp.com',
  projectId: 'consistent-income',
  storageBucket: 'consistent-income.appspot.com',
  messagingSenderId: '300927374427',
  appId: '1:300927374427:web:41b2b33563e82a50f09b7e',
  measurementId: 'G-PPQVGR4RD2',
}

console.log('Firebase initializing...')

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)

console.log('Firebase initialized')
