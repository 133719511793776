import React from 'react'
import { useAuthState } from '../../services/authService'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import OauthPopup from 'react-oauth-popup'
import { config } from '../../config/config'

const UserInfo = () => {
  const { auth, isAuthenticated, logout, authenticate } = useAuthState()

  const onCode = (code: string, params: any) => {
    authenticate(code, true)
  }
  const onClose = () => console.log('Auth popup closed')

  if (!isAuthenticated)
    return (
      <div>
        {/*@ts-ignore */}
        <OauthPopup
          url={`https://auth.tdameritrade.com/auth?response_type=code&client_id=${
            config.clientID
          }%40AMER.OAUTHAP&scope=AccountAccess&redirect_uri=${encodeURIComponent(
            config.redirectURI,
          )}`}
          onCode={onCode}
          onClose={onClose}
          title={'Authenticate'}>
          <div className="flex flex-shrink-0 p-4 ">
            <button
              type="button"
              className="flex flex-1 justify-center rounded-md border border-transparent bg-primary-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-300 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
              <ArrowLeftOnRectangleIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Login with TD
            </button>
          </div>
        </OauthPopup>
      </div>
    )

  return (
    <div className="flex flex-shrink-0 bg-primary-900 p-4 ">
      <a href="#" className="group block w-full flex-shrink-0">
        <div className="flex items-center">
          <div>
            <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
              <svg
                className="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </div>
          <div className="ml-3" onClick={() => logout()}>
            <p className="text-sm font-medium text-white">
              {auth.userId.substring(0, 4)}******
            </p>
            <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
              Logout
            </p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default UserInfo
