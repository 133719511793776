import { config } from '../config/config'

const codeToAuthToken = async (code: string) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    const urlencoded = new URLSearchParams()
    urlencoded.append('grant_type', 'authorization_code')
    urlencoded.append('refresh_token', '')
    urlencoded.append('access_type', 'offline')
    urlencoded.append('code', code)
    urlencoded.append('client_id', config.clientID)
    urlencoded.append('redirect_uri', config.redirectURI)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    }

    const response = await fetch(
      'https://api.tdameritrade.com/v1/oauth2/token',
      // @ts-ignore
      requestOptions,
    )

    const result = await response.json()

    console.log({ result })

    if (result.error) return { error: result.error }

    return {
      access_token: result.access_token,
      expires_in: result.expires_in,
      refresh_token: result.refresh_token,
      refresh_token_expires_in: result.refresh_token_expires_in,
      scope: result.scope,
      token_type: result.token_type,
    }
  } catch (e) {
    return { error: e }
  }
}

const refreshToken = async (refreshToken: string) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    const urlencoded = new URLSearchParams()
    urlencoded.append('grant_type', 'refresh_token')
    urlencoded.append('refresh_token', refreshToken)
    urlencoded.append('client_id', config.clientID)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    }

    const response = await fetch(
      'https://api.tdameritrade.com/v1/oauth2/token',
      // @ts-ignore
      requestOptions,
    )

    const result = await response.json()

    console.log({ result })

    if (result.error) return { error: result.error }

    return {
      access_token: result.access_token,
      expires_in: result.expires_in,
      refresh_token: result.refresh_token,
      refresh_token_expires_in: result.refresh_token_expires_in,
      scope: result.scope,
      token_type: result.token_type,
    }
  } catch (e) {
    return { error: e }
  }
}

const getUserInfo = async (token: string) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    }

    const response = await fetch(
      `https://api.tdameritrade.com/v1/userprincipals`,
      requestOptions,
    )

    const result = await response.json()

    return result
  } catch (e) {
    return { error: e }
  }
}

const tdService = {
  codeToAuthToken,
  getUserInfo,
  refreshToken,
}

export { tdService as default }
