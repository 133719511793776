import { useAuthState } from '../../../../services/authService'
import OauthPopup from 'react-oauth-popup'
import { config } from '../../../../config/config'
import React from 'react'

export const NoAuth = () => {
  const { authenticate } = useAuthState()
  const onCode = (code: string, params: any) => {
    authenticate(code)
  }
  const onClose = () => console.log('Auth popup closed')

  return (
    <div className={'min-h-screen'}>
      <div className="relative overflow-hidden bg-white py-16 ">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
          <div
            className="relative mx-auto h-full max-w-prose text-lg"
            aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384">
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384">
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="block text-center text-base font-semibold uppercase tracking-wide text-primary-600">
                Buy Write Expected Returns
              </span>
              <span className="mt-2 block text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Let's get started
              </span>
            </h1>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              To start using the expected returns tool you will first need to
              sign in with your TD Ameritrade account. This gives you access to
              use our suite of data gathering tools.
            </p>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              If you don't have an account, you can sign up for free.
            </p>
          </div>
          <div className="prose prose-lg prose-teal mx-auto mt-6 text-gray-500">
            {/*@ts-ignore */}
            <OauthPopup
              url={`https://auth.tdameritrade.com/auth?response_type=code&client_id=${
                config.clientID
              }%40AMER.OAUTHAP&scope=AccountAccess&redirect_uri=${encodeURIComponent(
                config.redirectURI,
              )}`}
              onCode={onCode}
              onClose={onClose}
              title={'Authenticate'}>
              <button className="block w-full rounded-md border border-transparent bg-primary-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10 ">
                Login with TD Ameritrade
              </button>
            </OauthPopup>
          </div>
        </div>
      </div>
    </div>
  )
}
